import gql from 'graphql-tag'

const detailResponse = `
  id tag jobDescriptionId options
  paperId paper {
    id type code name
  }
`

export const LIST_ATTENDANCE = (templateType) => gql`query LIST_ATTENDANCE ($jobDescriptionType: String!, $jobDescriptionId: Int!, $q: FilterInput) {
  attendances: listContactJobDescription${templateType}Attendance (jobDescriptionType: $jobDescriptionType, jobDescriptionId: $jobDescriptionId, q: $q) {${detailResponse}}
}`

export const CREATE_ATTENDANCE = (templateType) => gql`mutation CREATE_ATTENDANCE ($jobDescriptionType: String!, $jobDescriptionId: Int!, $input: ContactJobDescription${templateType}AttendanceInput!) {
  createAttendance: createContactJobDescription${templateType}Attendance (jobDescriptionType: $jobDescriptionType, jobDescriptionId: $jobDescriptionId, input: $input) {${detailResponse}}
}`

export const UPDATE_ATTENDANCE = (templateType) => gql`mutation UPDATE_ATTENDANCE ($jobDescriptionType: String!, $jobDescriptionPaperJunctionId: Int!, $input: ContactJobDescription${templateType}AttendanceInput!) {
  updateAttendance: updateContactJobDescription${templateType}Attendance (jobDescriptionType: $jobDescriptionType, jobDescriptionPaperJunctionId: $jobDescriptionPaperJunctionId, input: $input) {${detailResponse}}
}`

export const DESTROY_ATTENDANCE = (templateType) => gql`mutation DESTROY_ATTENDANCE ($jobDescriptionType: String!, $jobDescriptionPaperJunctionId: Int!) {
  destroyAttendance: destroyContactJobDescription${templateType}Attendance (jobDescriptionType: $jobDescriptionType, jobDescriptionPaperJunctionId: $jobDescriptionPaperJunctionId) {id}
}`

export const DROPDOWN_ATTENDANCE_PAPERS = (templateType) => gql`query DROPDOWN_ATTENDANCE_PAPERS ($jobDescriptionType: String!, $q: FilterInput) {
  items: listContactJobDescription${templateType}AttendanceDropdownPaper (jobDescriptionType: $jobDescriptionType, q: $q) {
    id code name type template
  }
}`
