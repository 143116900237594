<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">{{title}}</h3>
      </div>

      <div class="dashhead-toolbar">
        <div class=" dashhead-toolbar-item">
          <sgv-input-select
            :options="categories"
            v-model="selectedCategory"
            select="value">
            <template slot-scope="option">
              {{option.text}}
            </template>
          </sgv-input-select>
        </div>
      </div>
    </div>

    <hr class="my-3">

    <sgv-table
      v-if="['inactive', 'active'].includes(selectedCategory)"
      :rKey="rKey"
      :items="jobDescriptions"
      :headers="headers"
      :filter.sync="filter"
      :options.sync="options"
      :rowsPerPage="rowsPerPage">
      <template slot-scope="{item, hidden}">
        <tr>
          <td v-if="hidden.code">
            <router-link
              class="text-decoration-none"
              :to="toDetail(item.id)">
              <span
                :class="{
                  'text-danger': !item.isActive,
                  'text-warning': !item.approvedBy
                }">
                {{item.code}}
              </span>
            </router-link>
            <div class="">
              <fa
                icon="user-plus"
                v-if="item.isJobApplication"
                class="text-success mr-1">
              </fa>

              <fa
                icon="people-arrows"
                v-if="item.isJobTraining"
                class="text-warning mr-1">
              </fa>

              <fa
                icon="user-check"
                v-if="item.isJobEvaluation"
                class="text-info mr-1">
              </fa>
            </div>
          </td>
          <td v-if="hidden.position">{{ item.position }}</td>
        </tr>
      </template>

      <router-link
        slot="action"
        v-if="$auth.hasRole(`jobDescription:${jobDescriptionType}:add`)"
        :to="toDetail(0)">
        <button
          type="button"
          class="btn btn-link text-success">
          เพิ่ม
        </button>
      </router-link>
    </sgv-table>

    <div v-else-if="selectedCategory === 'treeview'">
      <ListPrintButton
        class="mb-3"
        ref="printEmp"
        :jobDescriptionType="jobDescriptionType"
        :templateType="templateType">
      </ListPrintButton>

      <div
        class="col-12"
        style="overflow-x: auto;"
        v-for="item in treeview"
        :key="item.id">
        <ListDiagram
          :item="item"
          :jobDescriptionType="jobDescriptionType"
          :templateType="templateType"
          @selected="$refs.printEmp.addEmployee($event)">
        </ListDiagram>
      </div>
    </div>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { LIST_JOBDESCRIPTION } from './graph'
import ListDiagram from './ListDiagram.vue'
import ListPrintButton from './ListPrintButton'

export default {
  mixins: [retainMixin],
  metaInfo () {
    return {
      title: `${this.subTitle}-${this.title}`
    }
  },
  props: {
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
    jobDescriptionType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      detailView: `ContactJobDescription${this.$form.capitalize(this.jobDescriptionType)}Detail`,
      rKey: `ContactJobDescription${this.$form.capitalize(this.jobDescriptionType)}List`,
      rFields: ['filter', 'options', 'selectedCategory'],
      headers: [
        {text: 'รหัส', value: 'code', sort: true, filter: true},
        {text: 'ตำแหน่ง', value: 'position', sort: true, filter: true},
      ],
      filter: {
        limit: null,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['code', 'position'],
        column: null,
        search: null,
        toolbar: null,
      },
      jobDescriptions: [],
      selectedCategory: 'active',
      categories: [
        {text: 'ใช้งาน', value: 'active'},
        {text: 'ยกเลิก', value: 'inactive'},
        {text: 'แผนผัง', value: 'treeview'},
      ],
      rowsPerPage: [
        { text: 'ทั้งหมด', value: null }
      ]
    }
  },
  apollo: {
    jobDescriptions: {
      query () {
        return LIST_JOBDESCRIPTION(this.templateType)
      },
      variables() {
        this.setRetaining()
        return {
          jobDescriptionType: this.jobDescriptionType,
          q: this.getFilter(this.filter)
        }
      },
      debounce: 150,
      fetchPolicy: 'network-only'
    }
  },
  computed: {
    treeview () {
      return this.jobDescriptions.filter(v => !v.parentId).map((v, i) => this.mapItem(v, i, this.jobDescriptions))
    }
  },
  methods: {
    mapItem (item, index, arr) {
      return {
        ...item,
        children: arr.filter(v => v.parentId === item.id).map((v, i) => this.mapItem(v, i, arr))
      }
    },
    getFilter (v) {
      let filter = {
        ...v,
        params: v.params || {}
      }
      if (this.selectedCategory === 'active') {
        filter.params.isActive = true
      } else if (this.selectedCategory === 'treeview') {
        filter = {
          params: {isActive: true}
        }
      } else if (this.selectedCategory === 'inactive') {
        filter.params.isActive = false
      }

      return filter
    },
    toDetail (id) {
      return {
        name: this.detailView,
        params: {jobDescriptionId: id}
      }
    }
  },
  created () {
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
  components: {
    ListDiagram,
    ListPrintButton
  }
}
</script>

<style lang="css" scoped>
</style>
