import gql from 'graphql-tag'

const detailResponse = `
  id code position department
  commander subordinate
  age gender education discipline
  skill etc responsibility training
  isActive parentId
  isJobApplication isJobTraining isJobEvaluation
  createdAt createdBy createdUser {id name}
  approvedAt approvedBy approvedUser {id name}
  abilities {
    id description name level isRequired
    monthExperience cron
  }
`

export const LIST_JOBDESCRIPTION = (templateType) => gql`query LIST_JOBDESCRIPTION ($jobDescriptionType: String!, $q: FilterInput) {
  jobDescriptions: listContactJobDescription${templateType} (jobDescriptionType: $jobDescriptionType, q: $q) {
    id code position isActive parentId
    isJobApplication isJobTraining isJobEvaluation
    department commander subordinate
    createdBy approvedBy
    employees {id code name}
  }
}`

export const DETAIL_JOBDESCRIPTION = (templateType) => gql`query DETAIL_JOBDESCRIPTION ($jobDescriptionType: String!, $jobDescriptionId: Int!) {
  jobDescription: detailContactJobDescription${templateType} (jobDescriptionType: $jobDescriptionType, jobDescriptionId: $jobDescriptionId) {${detailResponse}}
}`

export const CREATE_JOBDESCRIPTION = (templateType) => gql`mutation CREATE_JOBDESCRIPTION ($jobDescriptionType: String!, $input: ContactJobDescription${templateType}Input!) {
  createJobDescription: createContactJobDescription${templateType} (jobDescriptionType: $jobDescriptionType, input: $input) {${detailResponse}}
}`

export const UPDATE_JOBDESCRIPTION = (templateType) => gql`mutation UPDATE_JOBDESCRIPTION ($jobDescriptionType: String!, $jobDescriptionId: Int!, $input: ContactJobDescription${templateType}Input!) {
  updateJobDescription: updateContactJobDescription${templateType} (jobDescriptionType: $jobDescriptionType, jobDescriptionId: $jobDescriptionId, input: $input) {${detailResponse}}
}`

export const APPROVE_JOBDESCRIPTION = (templateType) => gql`mutation APPROVE_JOBDESCRIPTION ($jobDescriptionType: String!, $jobDescriptionId: Int!) {
  approveJobDescription: approveContactJobDescription${templateType} (jobDescriptionType: $jobDescriptionType, jobDescriptionId: $jobDescriptionId) {${detailResponse}}
}`

export const ROLLBACK_JOBDESCRIPTION = (templateType) => gql`mutation ROLLBACK_JOBDESCRIPTION ($jobDescriptionType: String!, $jobDescriptionId: Int!) {
  rollbackJobDescription: rollbackContactJobDescription${templateType} (jobDescriptionType: $jobDescriptionType, jobDescriptionId: $jobDescriptionId) {${detailResponse}}
}`

export const DESTROY_JOBDESCRIPTION = (templateType) => gql`mutation DESTROY_JOBDESCRIPTION ($jobDescriptionType: String!, $jobDescriptionId: Int!) {
  destroyJobDescription: destroyContactJobDescription${templateType} (jobDescriptionType: $jobDescriptionType, jobDescriptionId: $jobDescriptionId) {id}
}`

export const DROPDOWN_PARENTS = (templateType) => gql`query DROPDOWN_PARENTS ($jobDescriptionType: String!, $q: FilterInput) {
  items: listContactJobDescription${templateType}DropdownParent (jobDescriptionType: $jobDescriptionType, q: $q) {
    id code position
  }
}`
