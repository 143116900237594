<template>
  <div class="">
    <button
      type="button"
      class="btn btn-warning"
      @click="toggle = !toggle"
      style="text-decoration:none;">
      <fa icon="print"></fa>
      รายชื่อพนักงาน
    </button>

    <div class="mt-3" v-if="toggle">
      <div class="form-row">
        <sgv-input-text
          label="หัวกระดาษ"
          class="col-12 col-md-4"
          v-model="title">
        </sgv-input-text>

        <sgv-input-check-array
          class="col-12"
          label="แสดงผล"
          inline
          :options="options"
          select="value"
          v-model="selectedAttrs">
          <template slot-scope="option">
            {{option.text}}
          </template>
        </sgv-input-check-array>
      </div>

      <div class="">
        รายชื่อพนักงาน:
        <ul>
          <li v-for="(item, i) in selectedEmployees" :key="i">
            <fa icon="trash" class="text-danger mr-1" @click="deleteEmployee(i)"></fa>
            {{item.code}} ({{item.name}})
          </li>
        </ul>
      </div>

      <button type="button" class="btn btn-warning" @click="download">Download</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    jobDescriptionType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      toggle: false,
      options: [
        {text: 'รูป', value: 'image'},
        {text: 'รหัส', value: 'code'},
        {text: 'name', value: 'name'},
        {text: 'ตำแหน่ง', value: 'position'},
        {text: 'เบอร์มือถือ', value: 'mobile'},
        {text: 'email', value: 'email'}
      ],
      title: '',
      selectedAttrs: [],
      selectedEmployees: []
    }
  },
  methods: {
    addEmployee (value) {
      const foundIndex = this.selectedEmployees.findIndex(v => v.id === value.id)
      if (foundIndex === -1) {
        this.selectedEmployees.push(value)
      }
    },
    deleteEmployee (index) {
      this.selectedEmployees.splice(index, 1)
    },
    download () {
      this.$axios.post(`/jobDescription/${this.jobDescriptionType}/employees/print`, {
        title: this.title,
        attributes: this.selectedAttrs,
        employees: this.selectedEmployees.map(v => v.id)
      }, { responseType: 'blob'})
      .then(res => {
        let filename = 'jd.pdf'
        const a = window.document.createElement('a')
        a.href = window.URL.createObjectURL(res.data)
        a.download = filename
        a.click()
      }).catch(() => {
        this.$toasted.global.error("ไม่สามารถโหลดได้")
      })
    }
  }
}
</script>

<style lang="css" scoped>
</style>
