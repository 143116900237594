<template>
  <li>
    <span class="diagram-item-body">
      <router-link
        :to="toDetail(item.id)"
        class="text-decoration-none"
        :class="{
          'text-success': item.approvedBy,
          'text-warning': !item.approvedBy
        }">
        {{item.position}}
      </router-link>
      <div class="mb-2">
        <small>{{item.code}}</small>
      </div>
      <div v-if="item.department">
        แผนก: {{item.department}}
      </div>
      <div class="">
        <fa
          icon="user-plus"
          v-if="item.isJobApplication"
          class="text-success mr-1">
        </fa>

        <fa
          icon="people-arrows"
          v-if="item.isJobTraining"
          class="text-warning mr-1">
        </fa>

        <fa
          icon="user-check"
          v-if="item.isJobEvaluation"
          class="text-info mr-1">
        </fa>
      </div>
      <div class="mt-2" v-if="item.employees.length > 0">
        <span
          class="pointer"
          v-for="employee in item.employees"
          :key="employee.id"
          @click="bus(employee)">
          {{employee.code}} ({{employee.name}})<br>
        </span>
      </div>
    </span>
    <ul v-if="item.children && item.children.length > 0">
      <list-diagram-item
        v-for="child in item.children"
        :item="child"
        :jobDescriptionType="jobDescriptionType"
        :templateType="templateType"
        :key="child.id"
        @bus="bus">
      </list-diagram-item>
    </ul>
  </li>
</template>

<script>

export default {
  props: {
    jobDescriptionType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },
  name: 'list-diagram-item',
  data () {
    return {
      detailView: `ContactJobDescription${this.$form.capitalize(this.jobDescriptionType)}Detail`
    }
  },
  methods: {
    toDetail (id) {
      return {
        name: this.detailView,
        params: {jobDescriptionId: id}
      }
    },
    bus (value) {
      this.$emit('bus', value)
    }
  },
}
</script>

<style lang="css" scoped>
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

* {
  position: relative;
  margin: 0;
  padding: 0;

  border: 0 none;

  -webkit-transition: all ease .4s;
  -moz-transition: all ease .4s;
    transition: all ease .4s;
}

.nav {
  margin: 0;
  min-width: 500px;
  min-height: auto;
}

.nav ul {
  position: relative;
  padding-top: 20px;
}

.nav li {
  position: relative;
  padding: 20px 3px 0 3px;
  float: left;

  text-align: center;
  list-style-type: none;
}

.nav li::before, .nav li::after{
  content: '';
  position: absolute;
  top: 0;
  right: 50%;
  width: 50%;
  height: 20px;
  border-top: 1px solid #ccc;
}

.nav li::after{
  left: 50%;
  right: auto;

  border-left: 1px solid #ccc;
}

.nav li:only-child::after, .nav li:only-child::before {
  content: '';
  display: none;
}

.nav li:only-child{ padding-top: 0;}
.nav li:first-child::before, .nav li:last-child::after{
  border: 0 none;
}

.nav li:last-child::before{
  border-right: 1px solid #ccc;
  border-radius: 0 5px 0 0;
}

.nav li:first-child::after{
    border-radius: 5px 0 0 0;
}
.nav ul ul::before{
  content: '';
  position: absolute; top: 0; left: 50%;
  border-left: 1px solid #ccc;
  width: 0;
  height: 20px;
}

.nav li span.diagram-item-body{
  display: inline-block;
  padding: 5px 10px;

  border-radius: 5px;
  border: 1px solid #ccc;

  text-decoration: none;
  text-transform: uppercase;
  color: #ccc;
  font-family: arial, verdana, tahoma;
  font-size: 11px;
}
</style>
