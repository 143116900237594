import gql from 'graphql-tag'

const detailResponse = `
  id description name level isRequired
  monthExperience cron order
  papers {
    id type code name
  }
`

export const LIST_ABILITY = (templateType) => gql`query LIST_ABILITY ($jobDescriptionType: String!, $jobDescriptionId: Int!) {
  abilities: listContactJobDescription${templateType}Ability (jobDescriptionType: $jobDescriptionType, jobDescriptionId: $jobDescriptionId) {${detailResponse}}
}`

export const CREATE_ABILITY = (templateType) => gql`mutation CREATE_ABILITY ($jobDescriptionType: String!, $jobDescriptionId: Int!, $input: ContactJobDescription${templateType}AbilityInput!) {
  createAbility: createContactJobDescription${templateType}Ability (jobDescriptionType: $jobDescriptionType, jobDescriptionId: $jobDescriptionId, input: $input) {${detailResponse}}
}`

export const UPDATE_ABILITY = (templateType) => gql`mutation UPDATE_ABILITY ($jobDescriptionType: String!, $abilityId: Int!, $input: ContactJobDescription${templateType}AbilityInput!) {
  updateAbility: updateContactJobDescription${templateType}Ability (jobDescriptionType: $jobDescriptionType, abilityId: $abilityId, input: $input) {${detailResponse}}
}`

export const DESTROY_ABILITY = (templateType) => gql`mutation DESTROY_ABILITY ($jobDescriptionType: String!, $abilityId: Int!) {
  destroyAbility: destroyContactJobDescription${templateType}Ability (jobDescriptionType: $jobDescriptionType, abilityId: $abilityId) {id}
}`

export const MOVE_ABILITY = (templateType) => gql`mutation MOVE_ABILITY ($jobDescriptionType: String!, $abilityId: Int!, $step: Int!) {
  moveAbility: moveContactJobDescription${templateType}Ability (jobDescriptionType: $jobDescriptionType, abilityId: $abilityId, step: $step) {id}
}`

export const DROPDOWN_PAPERS = (templateType) => gql`query DROPDOWN_PAPERS ($jobDescriptionType: String!, $q: FilterInput) {
  papers: listContactJobDescription${templateType}AbilityDropdownPaper (jobDescriptionType: $jobDescriptionType, q: $q) {
    id code name type template
  }
}`

export const CREATE_ABILITY_PAPER = (templateType) => gql`mutation CREATE_ABILITY_PAPER ($jobDescriptionType: String!, $abilityId: Int!, $paperId: Int!) {
  createContactJobDescription${templateType}AbilityPaper (jobDescriptionType: $jobDescriptionType, abilityId: $abilityId, paperId: $paperId) {
    abilityId paperId
  }
}`

export const DESTROY_ABILITY_PAPER = (templateType) => gql`mutation DESTROY_ABILITY_PAPER ($jobDescriptionType: String!, $abilityId: Int!, $paperId: Int!) {
  destroyContactJobDescription${templateType}AbilityPaper (jobDescriptionType: $jobDescriptionType, abilityId: $abilityId, paperId: $paperId) {
    abilityId paperId
  }
}`
