<template>
  <nav class="nav">
    <ul>
      <ListDiagramItem
        :item="item"
        :jobDescriptionType="jobDescriptionType"
        :templateType="templateType"
        :key="item.id"
        @bus="$emit('selected', $event)">
      </ListDiagramItem>
    </ul>
  </nav>
</template>

<script>
import ListDiagramItem from './ListDiagramItem'

export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    jobDescriptionType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  components: {
    ListDiagramItem
  }
}
</script>

<style lang="css" scoped>
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

* {
  position: relative;
  margin: 0;
  padding: 0;

  border: 0 none;

  -webkit-transition: all ease .4s;
  -moz-transition: all ease .4s;
    transition: all ease .4s;
}

.nav {
  margin: 0;
  padding-bottom: 20px;
  width: 10000px;
  padding-top: 20px;
}
</style>
